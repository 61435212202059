<template>
  <b-container class="page registration-success">
    <div class="content-wrap narrow">
        <p class="text-center">
          <img src="@/assets/images/success.png" />
        </p>
        <h1 class="text-center">{{this.$t("success.title")}}</h1>

        <p class="text-center" v-html='this.$t("success.body")'>
        </p>
    </div>
  </b-container>
</template>
<script>
export default {};
</script>
